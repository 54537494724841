import getConfig from 'next/config'

import { AlgoliaAuction } from '@nx/algolia'
import { ArrowRightIcon } from '@nx/fire/assets'
import { AuctionType, Brand } from '@nx/global-types'
import { buildAuctionPath } from '@nx/helpers'
import { getTranslate } from '@nx/translations'

import { populateUrl } from '@web/helpers/utils/urlBuilders/populateUrl'
import { AuctionClickCallback } from '@web/types/app'

import {
  Auction,
  AuctionDetails,
  AuctionDetailsContainer,
  CTA,
  Flex,
  Image,
  ImageContainer,
  StyledHeading,
  Title,
} from './LiveAuctions.styles'
import translate from './LiveAuctions.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text } = getTranslate(translate)

interface LiveAuctionsProps {
  auctions: AlgoliaAuction[]
  auctionClickCallback?: AuctionClickCallback
}

export const LiveAuctions = ({
  auctions,
  auctionClickCallback,
}: LiveAuctionsProps) => {
  return (
    <>
      <StyledHeading $level={5} as="h2">
        {text('title')}
      </StyledHeading>

      <Flex>
        {auctions.map(
          ({
            id,
            auctionTitle,
            auctionType,
            biddingEngine,
            image,
            brand,
            slug,
          }) => (
            <Auction
              key={id}
              href={buildAuctionPath({
                auctionId: id,
                auctionName: auctionTitle,
                auctionType,
                biddingEngine,
                slug,
                domain:
                  brand === Brand.cars
                    ? publicRuntimeConfig.CARS_BASE_URL
                    : undefined,
              })}
              onClick={() => {
                auctionClickCallback?.({
                  auctionId: id,
                  category: 'Live Now',
                  action: 'live-auction-card.click',
                })
              }}
            >
              <AuctionDetailsContainer>
                <ImageContainer>
                  <Image
                    src={
                      image
                        ? populateUrl(image.url, image.URLParams, 'width=150')
                        : ''
                    }
                    width={0}
                    height={0}
                    alt={image?.caption || ''}
                    $coverImage={!!image}
                  />
                </ImageContainer>

                <AuctionDetails>
                  <Title $level="M" as="h3" title={auctionTitle}>
                    {auctionTitle}
                  </Title>

                  <CTA as="span" $level="M">
                    <span>
                      {auctionType === AuctionType.live
                        ? text('live')
                        : text('ending')}
                    </span>
                    <ArrowRightIcon />
                  </CTA>
                </AuctionDetails>
              </AuctionDetailsContainer>
            </Auction>
          )
        )}
      </Flex>
    </>
  )
}
