import { useQuery } from '@tanstack/react-query'
import jsCookies from 'js-cookie'

import { getUser } from './getUser'

export function useGetUser(baseURL: string) {
  return useQuery({
    queryKey: ['user'],
    queryFn: () => {
      const cookies = {
        bonhams_access: jsCookies.get('bonhams_access') || '',
        bonhams_token: jsCookies.get('bonhams_token') || '',
      }

      return getUser(baseURL, cookies)
    },
    retry: false,
  })
}
