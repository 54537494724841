import { useQuery } from '@tanstack/react-query'

import { CheckUserLoginStatusReturn } from '@nx/auth'

import { getUserAddresses } from './getUserAddresses'

export function useGetUserAddresses({
  clientNo,
  baseUrl,
}: UseUserAddressesProps) {
  return useQuery({
    queryKey: ['userAddresses', { clientNo }],
    queryFn: async () => {
      const response = await getUserAddresses(clientNo, baseUrl)

      return response
    },
    retry: 1,
    enabled: Boolean(clientNo),
    refetchOnWindowFocus: true,
    staleTime: 300000, // 5 minutes.
  })
}

export interface UseUserAddressesProps {
  clientNo: CheckUserLoginStatusReturn['user']['iClientNo']
  baseUrl: string
}
